import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
import { Code } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
import { Component } from "react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const BackButton = makeShortcode("BackButton");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const DoDontChecklist = makeShortcode("DoDontChecklist");
const DoDontChecklistHeading = makeShortcode("DoDontChecklistHeading");
const DoDontChecklistItem = makeShortcode("DoDontChecklistItem");
const DoDontComponent = makeShortcode("DoDontComponent");
const Do = makeShortcode("Do");
const CaptionTitle = makeShortcode("CaptionTitle");
const ImageCaption = makeShortcode("ImageCaption");
const Dont = makeShortcode("Dont");
const ImageWrapper = makeShortcode("ImageWrapper");
const Link = makeShortcode("Link");
const VercelAnalytics = makeShortcode("VercelAnalytics");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ArticlePageWrapper mdxType="ArticlePageWrapper">
      <BackButton mdxType="BackButton" />
      <Heading as="h1" mdxType="Heading">Grammar and mechanics</Heading>
      <Text mdxType="Text">
  Consistent language helps users navigate apps confidently. Like visuals, words
  that blend seamlessly into the interface strengthen our identity and build
  trust.
      </Text>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Basics</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use American spellings: license, organize, synchronize, center, dependent,
    favorite.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Choose simple tenses. For most cases, prefer <i>was</i> over <i>has been</i>.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/4597e686-eefa-4a04-a0ba-4da256f32229" alt="Simple and succinct success message title." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Simplify your message titles to "Task created," "Payment failed," etc.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
    <img src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/4516e052-9a7b-42fc-a80b-a62631eb79a0" alt="Overly complex success message title." />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Avoid indirect present perfect tense, and skip redundant{" "}
      <i>successfully</i>.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Use sentence case</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    It's easier to maintain, read, and understand.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Apply to all interface elements, including buttons and headers, unless it's
    an exception.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Capitalize the first letter of a sentence, UI label, proper nouns, days,
    months, etc.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't capitalize the names of features, such as <i>column view</i> or{" "}
    <i>post function.</i>
  </DoDontChecklistItem>
  <DoDontComponent vertical docsTheme="brand" mdxType="DoDontComponent">
    <Dont variant={`blockquote`} mdxType="Dont">
      A feature is anything that does something within an app. While some
      features might seem special, their significance is subjective and often
      perceived differently by users.
    </Dont>
  </DoDontComponent>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't capitalize <i>project</i>, <i>scope</i>, <i>sprint</i>, or{" "}
    <i>iteration</i>, as those are common nouns.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't capitalize <i>a</i> in <i>the app</i> when referring to your product.
    We're not braggy.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/ea550446-56fd-4ec2-b88d-64c233b2b81d" alt="Example of a consistently used sentence case." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Consistent use of sentence case makes information easier to follow and
      understand.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
    <img src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/8355f927-dc60-4dae-8f80-4780b9e29282" alt="Example of combining sentence and title case." />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Inconsistent capitalization makes apps seem sloppy and unprofessional.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Exceptions to sentence case</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    If a term comes from another ecosystem, respect its original spelling.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use all caps to write "Save PDF" or "Export CSV." Add lowercase <i>s</i> for
    plurals, like <i>JPGs</i>.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use all caps for other necessary acronyms.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use all caps for text in distinct UI elements such as lozenges or dropdown
    group labels.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/321cdb15-3b70-487d-9f49-18efb08f4c49" alt="Examples of lozenges and dropdown group labels written in all caps." />
      </ImageWrapper>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Terminal punctuation</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Aim for short tooltips and inline messages without periods.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use periods when there's more than one sentence or other punctuation marks.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use periods in sentences followed by links ("New tokens available.{" "}
    <Link href={`https://www.fuegokit.design/tokens/`} mdxType="Link">Go to Fuegokit</Link>").
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use terminal punctuation to end those links.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use terminal punctuation for titles and headings.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/af5779bc-d6d5-4e40-b985-78df100506f4" alt="Short tooltip that doesn't require terminal punctuation." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Omitting periods helps short text elements flow smoothly and look
      informative.
    </ImageCaption>
  </Do>
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/fuegokit/appfire-design-systems-brand-svg/assets/153517119/f9bde2d8-865b-433a-8676-16172b45b5b3" alt="Tooltip consisting of 2 sentences, requiring terminal punctuation." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Consistency requires terminal punctuation for multiple and complex
      sentences.
    </ImageCaption>
  </Do>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Other punctuation</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Improve clarity with an Oxford comma to join independent clauses or list
    multiple items.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use quotation marks only to quote someone. To distinguish text, use{" "}
    <i>italics</i> or <b>bold</b>.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Reserve ellipses to indicate an action in progress, like <i>Loading…</i>.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use colons to introduce a bulleted list in body text but not after headings.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid semicolons; use commas or split your sentence into two.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't overdo it with exclamation marks! Too many, or too often, are likely
    to be annoying!
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid parentheses except for definitions, as they create ambiguity and
    disrupt scanning.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent vertical docsTheme="brand" mdxType="DoDontComponent">
  <Do mdxType="Do">
    <img src="https://github.com/user-attachments/assets/3379f702-5332-4ae5-872e-275da12bb4ae" alt="4 examples of simple and efficient content writing without parentheses." />
    <ImageCaption mdxType="ImageCaption">
      Make it easy to grasp important details and what action to take.
    </ImageCaption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/user-attachments/assets/f8367e98-dcc7-4915-b7a6-c3a9e3d9f91c" alt="4 examples of unnecessary parentheses use across the interface." />
    <ImageCaption mdxType="ImageCaption">
      Don't break the flow or make people decide if singular or plural is more
      relevant.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Dashes and hyphens</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use hyphens (-) to join words (well-known, high-quality, etc.) or indicate a
    range (0-10).
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Choose unhyphenated spelling if both are correct (email, post function,
    subtask, etc.).
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use em dashes (—) to break up a sentence—and don't add spaces.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use em dashes sparingly. Often it's better to start a new sentence.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use ampersand (&) in body text unless it's a part of the product name.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Limit ampersands in other UI elements. They are less accessible than{" "}
    <i>and</i>.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Contractions</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use simple contractions (you'll, it's, there's, etc.) to sound natural.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use negative contractions (can't, don't, won't, etc.) most of the time.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Consider spelling out negatives when overlooking <i>not</i> could have
    critical implications.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Spell out noun + verb contractions. <i>Browser is</i> reads better than{" "}
    <i>browser's</i>.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid using complex phrases such as <i>should have</i> and their
    contractions like <i>should’ve</i>.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Abbreviations and acronyms</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Write <i>for example</i> or <i>such as</i> for more conversational copy.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <i>e.g.,</i> (followed by a comma) only when space is minimal, such as
    in placeholders.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <i>etc.</i> sparingly.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    If an acronym is necessary, define it first using parentheses.
  </DoDontChecklistItem>
  <DoDontComponent vertical docsTheme="brand" mdxType="DoDontComponent">
    <Do variant={`blockquote`} mdxType="Do">
      The Universal Plugin Manager (UPM) provides a powerful and user-friendly
      interface. Through the UPM, you can browse, try, and buy apps.
    </Do>
  </DoDontComponent>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't explain common acronyms that are not app-specific, such as PDF, HTML,
    or FAQ.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Numbers and numerals</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use numerals to improve scanning for specific data.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use numerals for dates and times, units, steps, instructions, and formulas.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    In general contexts, use numerals for <i>2</i> and above.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    In general contexts, write <i>zero</i> and <i>one</i> in words to avoid
    confusion with <i>o</i> and <i>l</i>.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">UI references</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Aim to write about values and capabilities, not UI elements.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When a UI element reference is necessary, match the case and punctuation
    exactly.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <b>bold</b> for buttons, checkboxes, fields, labels, options, and
    toggles.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <i>italics</i> for page and tab names, headings, and dialog titles.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't add terms like <i>button</i> or <i>option</i>. Having a clear context
    makes them redundant.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid saying <i>above</i>, <i>below</i>, <i>here</i>, etc., as those are
    relative and inaccessible.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent docsTheme="brand" mdxType="DoDontComponent">
  <Do variant={`blockquote`} mdxType="Do">
    Clear, direct instructions help people quickly understand what to do:<br></br>
    "Select <b>Hide parent tasks</b> to see only tasks matching your criteria."
  </Do>
  <Dont variant={`blockquote`} mdxType="Dont">
    Redundant terms complicate things:<br></br>
    "Select the <b>Hide parent tasks</b> option below to see only tasks matching
    your criteria."
  </Dont>
      </DoDontComponent>
      <hr />
      <p>{`Do you have feedback for us? Share it on the #design-systems channel in Slack.`}</p>
    </ArticlePageWrapper>
    <VercelAnalytics mdxType="VercelAnalytics" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      